@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  /* Чтобы не прыгал scrollbar при открытии модального окна */
  html,
  body {
    scrollbar-gutter: stale;
  }

  .content-box p {
    @apply mb-2 text-black dark:text-white;
  }

  .content-box a {
    @apply mb-2 text-sky-600 dark:text-sky-400 hover:underline;
  }
  .content-box ul {
    @apply list-disc px-3 text-black dark:text-white;
  }
  .content-box li {
    @apply mb-2 ml-5;
  }

  .content-box h3 {
    @apply font-bold text-[1.2rem] text-black dark:text-white my-5;
  }
  .content-box h4 {
    @apply font-bold text-[1rem] text-black dark:text-white mb-2;
  }
  /* scrollbar */

  ::-webkit-scrollbar {
    @apply w-1 h-1 bg-[#c6c6c6] rounded-full;
  }
  ::-webkit-scrollbar-track {
    @apply bg-[#c6c6c6] rounded-full;
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-sky-600  rounded-full;
  }

  .about-me-content p {
    @apply text-black dark:text-white mb-4;
  }
  .cookies-modal-content a {
    @apply text-sky-600 dark:text-sky-400 hover:underline;
  }
}

@layer components {
  .btn-contact-form {
    @apply flex items-center justify-center gap-5 h-12 w-full py-1 px-5  sm:max-w-[15rem] sm:mx-auto transition-all duration-200 border bg-transparent  border-sky-600 dark:border-sky-600 text-black dark:text-white text-lg rounded-md;
  }
  .btn {
    @apply relative z-10 inline-flex justify-center items-center w-full sm:max-w-[16rem] px-5 py-2 transition-all duration-200 border border-sky-600 dark:border-sky-600 text-black dark:text-white text-lg rounded-md bg-white dark:bg-neutral-800   hover:bg-sky-600 dark:hover:bg-sky-600 hover:text-white;
  }
  .btn-hero {
    @apply flex justify-center items-center w-full gap-4 px-5 py-2 transition-all duration-200 border border-sky-600 dark:border-sky-600 text-black dark:text-white text-lg rounded-md bg-transparent  hover:bg-sky-600 hover:text-white hover:dark:text-white;
  }
  .btn-cookies {
    @apply flex justify-center items-center w-full sm:w-[14rem] lg:w-full px-2 py-1 transition-all duration-200 border border-sky-600 dark:border-sky-600 text-black dark:text-white text-lg bg-transparent hover:bg-sky-600 hover:text-white;
  }
  .btn-cookies.active {
    @apply flex justify-center items-center w-full sm:w-[14rem] lg:w-full px-2 py-1 transition-all duration-200 border border-sky-600 dark:border-sky-600 bg-sky-600 dark:bg-sky-600 text-white dark:text-white text-lg hover:bg-sky-600 hover:text-white;
  }
  .btn-filter {
    @apply w-full sm:w-6/12 md:w-7/12 lg:w-5/12 xl:w-6/12 cursor-pointer rounded-sm py-1 px-4 border border-sky-600 text-center text-black dark:text-white transition-all duration-200  hover:bg-sky-600 hover:text-white dark:hover:bg-sky-600 hover:dark:text-white;
  }
  .main-menu li {
    @apply border-b border-b-colorBorderNavMenu dark:border-b-colorBorderNavMenuDark last:border-b-0 duration-200 hover:bg-sky-600;
  }
}

@layer utilities {
  .error::placeholder {
    @apply text-red-500 dark:text-red-400;
  }
  [type='checkbox'].error {
    @apply border-2  border-red-400 dark:border-red-400;
  }
  /* Form */
  [type='text']:focus,
  input:where(:not([type])):focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [multiple]:focus,
  textarea:focus {
    @apply shadow-none border-colorBorder border-b-4 border-b-sky-600 dark:border-b-sky-500 ring-0 outline-none appearance-none;
  }

  .clip-education {
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  .form-checkbox {
    @apply focus:ring-0 focus:ring-offset-0 border bg-white dark:bg-black border-sky-600 dark:border-sky-500 text-sky-600;
  }
  .form-checkbox:checked {
    @apply border-sky-600 dark:border-sky-500 bg-sky-600 dark:bg-black;
  }
  .accept-form-link a {
    @apply font-bold text-sky-600 dark:text-sky-400 hover:underline;
  }
}
.grecaptcha-badge {
  display: none;
}

@media screen and (max-width: 1024px) {
  .fslightbox-slide-btn-next-container,
  .fslightbox-slide-btn-previous-container {
    display: none !important;
  }
}

.fslightbox-slide-btn {
  background-color: #3a3a3a !important;
}
